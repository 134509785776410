<script lang="ts" setup>
const props = withDefaults(
	defineProps<{
		wheelSize?: number;
		isPercentEnabled?: boolean;
		clearSectors?: boolean;
	}>(),
	{
		wheelSize: 50
	}
);
const wheelSizeCss = `${props.wheelSize}px`;
const prepareImgUrl = useImage();
const imgBorder = `url(${prepareImgUrl(`/nuxt-img/wheel-of-treasure/wheel/base-border.png`, {
	format: "webp",
	width: "550",
	height: "550",
	quality: "100"
})})`;
</script>
<template>
	<div :class="['fake-wheel', { double: isPercentEnabled }]">
		<NuxtImg
			class="arrow"
			:src="`/nuxt-img/wheel-of-treasure/wheel/${isPercentEnabled ? 'arrow-light' : 'arrow-light-coins'}.png`"
			format="webp"
			:width="isPercentEnabled ? 178 : 168"
			:height="isPercentEnabled ? 301 : 254"
			quality="100"
			alt="arrow"
			loading="lazy"
		/>
		<div v-if="isPercentEnabled" class="percents">
			<AAnimationDrum antiClockWise>
				<NuxtImg
					:src="`/nuxt-img/wheel-of-treasure/wheel/${clearSectors ? 'sectors-percent-clear' : 'sectors-percent'}.png`"
					format="webp"
					width="414"
					height="414"
					quality="100"
					alt="sectors percent"
					loading="lazy"
				/>
			</AAnimationDrum>
		</div>
		<div class="coins">
			<AAnimationDrum>
				<NuxtImg
					:src="`/nuxt-img/wheel-of-treasure/wheel/${clearSectors ? 'sectors-coins-clear' : 'sectors-coins'}.png`"
					format="webp"
					:width="clearSectors ? 310 : 620"
					:height="clearSectors ? 310 : 620"
					quality="100"
					alt="sectors coins"
					loading="lazy"
				/>
			</AAnimationDrum>
		</div>

		<NuxtImg
			class="center"
			:src="`/nuxt-img/wheel-of-treasure/wheel/${isPercentEnabled ? 'center-light' : 'center'}.png`"
			format="webp"
			:width="isPercentEnabled ? 176 : 110"
			:height="isPercentEnabled ? 235 : 110"
			quality="100"
			alt="center"
			loading="lazy"
		/>
	</div>
</template>

<style lang="scss" scoped>
.fake-wheel {
	position: relative;
	z-index: 1;
	width: v-bind(wheelSizeCss);
	height: v-bind(wheelSizeCss);
	background: v-bind(imgBorder) no-repeat center center / contain;
	& > * {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	img {
		width: 100%;
		height: auto;
	}
	.arrow {
		z-index: 5;
		top: 20%;
		width: 32%;
	}
	.coins {
		z-index: 4;
		width: 79%;
		height: 79%;
	}
	.center {
		z-index: 6;
		width: 25%;
	}
}
.fake-wheel.double {
	.arrow {
		z-index: 3;
		top: 25%;
		width: 32%;
	}
	.percents {
		z-index: 2;
		width: 76%;
		height: 76%;
	}
	.coins {
		width: 56.7%;
		height: 56.7%;
	}
	.center {
		top: 39%;
		width: 30%;
	}
}
</style>
